<template>
  <div class="d-flex justify-content-start">
    <div
      class="px-1"
      v-for="(count, reaction) in reactions_summary"
      :key="reaction"
      v-show="count"
    >
      <img style="width: 20px" :src="image(reaction)" />
      <span class="px-1">{{ count }}</span>
    </div>
  </div>

  <div class="border-top position-relative">
    <div
      class="bg-white border rounded shadow-sm position-absolute py-2"
      style="bottom: 5px;
    width: 310px;
    right: -10px !important;
    border-radius: 50px !important;
    background-color: #f8f9fa !important;"
      v-show="show_reaction_types"
    >
      <button
        @click="toggleRaction(type)"
        class="btn pb-0 px-1"
        v-for="type in types"
        :key="type"
      >
        <img :src="image(type)" style="width:40px !important;" />
      </button>
    </div>

    <button
      @click="show_reaction_types = !show_reaction_types"
      class="btn btn-link text-white px-0 pb-0" 
      style="text-decoration: none !important; float:right;"
    >
      <span v-if="auth_reaction">
       <!-- <img :src="image(auth_reaction)" class="w-25" />
        {{ auth_reaction }}-->
        <i style="opacity:0.3 !important;" class="fa fa-heart-o f-24" aria-hidden="true"></i>
      </span> 
      <span v-else><i class="fa fa-heart-o f-24" aria-hidden="true"></i></span>
    </button>
  </div>
</template>

<script>
export default {
  props: ["summary", "reacted"],

  data() {
    return {
      show_reaction_types: false,
      types: ["like", "love", "haha", "angry", "sad", "wow"],
      reactions_summary: { ...this.summary },
      auth_reaction: this.reacted ? this.reacted.type : null,
    };
  },

  methods: {
    image(type) {
      return `/resources/images/reactions_${type}.png`;
    },

    toggleRaction(reaction) {
      let path = window.location.href;
      let old_reaction = this.auth_reaction;

      axios.post(`${path}/reaction`, { reaction }).catch(() => {
        this.saveReaction(old_reaction, reaction);
      });

      this.show_reaction_types = false;
      this.saveReaction(reaction, old_reaction);
    },

    saveReaction(new_reaction, old_reaction) {
      this.resetReactionsSummary(new_reaction, old_reaction);

      if (this.auth_reaction === new_reaction) {
        this.auth_reaction = null;
        return;
      }

      this.auth_reaction = new_reaction;
    },

    resetReactionsSummary(new_reaction, old_reaction) {
      if (old_reaction) {
        this.reactions_summary[old_reaction]--;
      }

      if (new_reaction && new_reaction !== old_reaction) {
        if (!this.reactions_summary[new_reaction]) {
          this.reactions_summary[new_reaction] = 1;
          return;
        }

        this.reactions_summary[new_reaction]++;
      }
    },
  },
};
</script>
